import PropTypes from "prop-types";
import React from "react";
import ReactContentfulImage from "react-contentful-image";

import EditorialTitle from "@/components/EditorialTitle";
import ImageSizes from "@/utils/helpers/images";
import { getStyleObject } from "@/utils/helpers/module_style_tags";
import withScreenDimensions from "@/utils/helpers/screen_dimensions";

import MobileStoreLinks from "../mobile-stores-links";
import Styles from "./styles.module.scss";

const Callout = (props) => {
  const {
    editorialTitle,
    image,
    isThemeLight,
    showMobileStoreLinks,
    marginBottom,
    anchorTag,
  } = props.data;
  const { pageName, isDesktop } = props;

  const pageEditorialWrapper = Styles[`callout__editorialTitle-${pageName}`];
  const editorialTitleWrapper =
    pageName && pageEditorialWrapper
      ? pageEditorialWrapper
      : Styles.calloutEditorialTitle;

  const {
    fields: {
      title,
      description,
      file: { url },
    },
  } = image;
  const desktopMarginBottom = isDesktop ? getStyleObject({ marginBottom }) : {};

  return (
    <section
      className={`${Styles.callout} ${
        isThemeLight && Styles.calloutIsThemeLight
      }`}
      data-attr-module-name="moduleCallout"
      data-attr-scroll={anchorTag}
      style={desktopMarginBottom}
    >
      <div className="row">
        <div
          className={`${editorialTitleWrapper} ${pageName} col-lg-8 order-sm-2 order-md-2 order-lg-1 flex-column`}
        >
          <EditorialTitle
            data={editorialTitle}
            isThemeLight={isThemeLight}
            styles={isThemeLight && Styles}
          />
          {showMobileStoreLinks && <MobileStoreLinks />}
        </div>
        <div
          className={`${Styles.calloutImageContainer} p-0 col-lg-8 order-sm-1 order-md-1 order-lg-2`}
        >
          <ReactContentfulImage
            alt={description}
            sizes={ImageSizes.callout}
            src={url}
            title={title}
          />
        </div>
      </div>
    </section>
  );
};

Callout.propTypes = {
  data: PropTypes.shape({
    anchorTag: PropTypes.string,
    editorialTitle: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
    isThemeLight: PropTypes.bool.isRequired,
    marginBottom: PropTypes.string.isRequired,
    showMobileStoreLinks: PropTypes.bool.isRequired,
  }),
  isDesktop: PropTypes.bool.isRequired,
  pageName: PropTypes.string,
};

Callout.defaultProps = {
  data: {
    isThemeLight: false,
    showMobileStoreLinks: false,
  },
};

export default withScreenDimensions(Callout);
